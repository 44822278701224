
import AllSubscriptions from '@/components/subscriptions/AllSubscriptions.vue'
import HoursOverview from '@/components/subscriptions/HoursOverview.vue'
import MissingOverview from '@/components/subscriptions/MissingOverview.vue'
import AddSubscriptions from '@/components/subscriptions/AddSubscriptions.vue'
import Finances from '@/components/subscriptions/Finances.vue'

import youSure from '@/utils/ConfirmationModal.ts'
import AlertModal from '@/components/AlertModal.vue'

import { mapState, mapGetters } from 'vuex'
import { UNSUBSCRIBE_ALL_REQUEST } from '@/store/actions/subscription.ts'
import { SET_SETTINGS_REQUEST } from '@/store/actions/setting.ts'
import { DELETE_ALL_EXCURSIONS_REQUEST } from '@/store/actions/excursion.ts'

export default{
    name: 'Subscriptions',
    components: {
        HoursOverview,
        MissingOverview,
        AllSubscriptions,
        AddSubscriptions,
        Finances,
        AlertModal,
    },
    data() {
        return {
            scope: null,
            refresh_subscriptions_id: null,
            errors: [],
            success: null,
            keuzedelen_subscription_preferences: null,
        }
    },
    methods: {
        deleteAllSubscriptions() {
            let you_sure_msg = 'Weet je zeker dat je <b>alle ' +
            (this.isXWeek ? 'X-Week' : (this.isKeuzedeel ? 'keuzedelen' : '????' ))
            + ' inschrijvingen</b> wilt <b>verwijderen?</b>'
            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) this.$store.dispatch(UNSUBSCRIBE_ALL_REQUEST, this.scope)
                        .then(() => {
                            this.success = 'Alle inschrijvingen zijn verwijderd'
                        })
                        .catch((err) => {
                            this.errors.push(err)
                        })
                })
        },
        deleteAllExcursions() {
            let you_sure_msg = 'Weet je zeker dat je <b>alle ' +
            this.scope
            + ' activiteiten</b> wilt <b>verwijderen?</b><br /><br />' +
                'Alle inschrvingen en betalingsgegevens zullen ook worden verwijderd.'
            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) this.$store.dispatch(DELETE_ALL_EXCURSIONS_REQUEST, this.scope)
                        .then(() => {
                            this.success = 'Alle activiteiten (en inschrijvingen) zijn verwijderd!'
                        })
                        .catch((err) => {
                            this.errors.push(err)
                        })
                })
        },
        toggleSubscriptionsState() {
            let setting = null
            let action = null
            if (this.isXWeek) {
                setting =
                    { name: 'subscriptions_open', value: !this.settings.subscriptions_open }
                action = ((this.settings.subscriptions_open) ? 'sluiten' : 'Openen')
            } else if (this.isKeuzedeel) {
                setting =
                    { name: 'subscriptions_keuzedelen_open', value: !this.settings.subscriptions_keuzedelen_open }
                action = ((this.settings.subscriptions_keuzedelen_open) ? 'sluiten' : 'Openen')
            } else {
                this.errors.push('Could not determine if this is for X-Week or Keuzedelen')
                return
            }
            let you_sure_msg = 'Weet je zeker dat je de ' +
            (this.isXWeek ? 'X-Week' : (this.isKeuzedeel ? 'keuzedelen' : '????' ))
            + ' inschrijvingen wilt <b>' + action + '</b>?'
            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) this.$store.dispatch(SET_SETTINGS_REQUEST, setting).then()
                })
        },
        setPreferenceAmount() {
            if (
                this.settings.keuzedelen_subscription_preferences < 1 ||
                this.settings.keuzedelen_subscription_preferences > 3
            ) {
                this.errors.push('Aantal inschrijvings voorkeuren is gelimiteerd tussen 1 en 3')
                return
            }

            let setting =
                { name: 'keuzedelen_subscription_preferences', value: this.settings.keuzedelen_subscription_preferences }
            let you_sure_msg = 'Weet je zeker dat je de aantal voorkeurs opties voor de keuzedelen inschrijvingen ' +
                'wijzigen naar: <b>' + this.settings.keuzedelen_subscription_preferences + '</b>?'
            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) this.$store.dispatch(SET_SETTINGS_REQUEST, setting).then()
                })
        },
        getKeuzedelenSubscribed() {
            return this.getSubscriptions.filter(sub => sub.scope === 'keuzedeel').length > 0
        }
    },
    created: function() {
        this.scope = this.$route.params.scope
    },
    destroyed() {
        clearInterval(this.refresh_subscriptions_id)
    },
    computed: {
        isXWeek: function() {
            return this.scope === 'x-week'
        },
        isKeuzedeel: function() {
            return this.scope === 'keuzedeel'
        },
        ...mapState({
            settings: (state: any) => state.setting.settings
        }),
        ...mapGetters([
            'getSubscriptions'
        ])
    },
    watch: {
        $route (to, from) {
            this.scope = this.$route.params.scope
        }
    }
}
