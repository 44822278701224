
import { mapState } from 'vuex'

import ProgressCircle from '@/components/ProgressCircle.vue'

import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable.vue'

export default{
    name: 'SubscriptionsFinances',
    components: {
        SubscriptionsTable,
        ProgressCircle
    },
    data() {
        return {
            mounted: false,

            payments: 0,
            to_pay: 0,
            payed_amount: 0,
            to_pay_amount: 0,

            filter_done: false,
            active_items: null,
            email_addresses: null,

            subscriptions_data: [],

            table_fields: [
                {
                    key: 'number',
                    label: 'Student nummer',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Voornaam',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Achternaam',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Activiteit/Reis',
                    sortable: true,
                    tdClass: 'excursion_column'
                },
                {
                    key: 'costs_students',
                    label: 'Kosten',
                    sortable: true
                },
                {
                    key: 'payed',
                    label: 'Betaald',
                    sortable: true
                },
            ],
        }
    },
    methods: {
        loadSubscriptions() {
            this.payments = 0
            this.to_pay = 0
            this.payed_amount = 0
            this.to_pay_amount = 0

            this.subscriptions_data = this.subscriptions.map(sub => {
                try {
                    let excursion = (({name, costs_students}) =>
                        ({name, costs_students}))(this.excursions_object[sub.excursion_id])
                    let price = excursion.costs_students
                    if (typeof(price) !== 'number' || price <= 0) {
                        return
                    }

                    if (sub.payed) {
                        this.payments++
                        this.payed_amount += price
                    } else {
                        this.to_pay++
                        this.to_pay_amount += price
                    }

                    let subscription_profile = {
                        ...this.profiles_object[sub.student_id],
                        ...excursion,
                        ...sub
                    }

                    // Just to make sure, the _id of the object must be the subscription _id!
                    subscription_profile._id = sub._id
                    return subscription_profile
                } catch (err) {
                    return {'first_name': 'error', 'last_name_prefix': err}
                }
            })
        },
        setActiveItems(items) {
            this.active_items = items
        },
        setEmailAddresses() {
            this.email_addresses = this.active_items
                .map(data => { return data.email })
                .join('; ')
        }
    },
    mounted() {
        this.mounted = true
        this.loadSubscriptions()
    },
    computed: {
        ...mapState({
            profiles_object: (state: any) => state.user.profiles_object,
            subscriptions: (state: any) => state.subscription.subscriptions,
            excursions_object: (state: any) => state.excursion.excursions_object
        }),
        getSubscriptions() {
            if (!(this.mounted)) return
            let subscriptions = this.subscriptions_data.filter(sub => sub != null)
            if (!this.filter_done) return subscriptions
            return subscriptions.filter(sub => !sub.payed)
        },
        getPayments() {
            if (!(this.mounted)) return
            return this.payments
        },
        getToPay() {
            if (!(this.mounted)) return
            return this.to_pay
        },
        getPayedAmount() {
            if (!(this.mounted)) return
            return this.payed_amount
        },
        getToPayAmount() {
            if (!(this.mounted)) return
            return this.to_pay_amount
        },
    },
    watch: {
        subscriptions: function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.loadSubscriptions()
            }
        },
        active_items: function() {
            if (this.email_addresses === null) return
            this.setEmailAddresses()
        }
    }
}
