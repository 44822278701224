const export_csv_fields = [
    {
        value: 'number',
        label: 'student nummer'
    },
    {
        value: 'excursion_name',
        label: 'Activiteit/Reis'
    },
    {
        value: 'costs_students',
        label: 'Kosten'
    },
    {
        value: 'first_name',
        label: 'Voornaam'
    },
    {
        value: 'last_name_prefix',
        label: 'Voorvoegsel'
    },
    {
        value: 'last_name',
        label: 'Achternaam'
    },
    {
        value: 'email',
        label: 'E-mail'
    },
    {
        value: 'document_type',
        label: 'Document type'
    },
    {
        value: 'document_number',
        label: 'document nummer'
    },
    {
        value: 'document_issue_date_parsed',
        label: 'Afgifte datum',
    },
    {
        value: 'document_validity_date_parsed',
        label: 'Geldig tot'
    },
    {
        value: 'document_country',
        label: 'Land van afgifte'
    },
    {
        value: 'document_surname',
        label: 'Achternaam'
    },
    {
        value: 'document_first_name',
        label: 'Voornaam'
    },
    {
        value: 'bday_parsed',
        label: 'Geboorte datum'
    },
    {
        value: 'gender',
        label: 'Geslacht'
    },
    {
        value: 'nationality',
        label: 'Nationaliteit'
    },
    {
        value: 'preference',
        label: 'Voorkeurs keuze'
    },
    {
        value: 'timestamp',
        label: 'Timestamp'
    }
]

export { export_csv_fields }
