
import Chart from 'chart.js'
 
export default {
    data() {
        return {
            id: Math.random().toString(36).substring(2, 15), // + Math.random().toString(36).substring(2, 15)
            chart: null
        }
    },
    methods: {
        createChart(data) {
            const ctx = document.getElementById('line-chart-' + this.id)
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.xdata,
                    datasets: [
                        { // another line graph
                            fill: false,
                            label: 'Aantal inschrijvingen',
                            data: data.ydata,
                            borderColor: [
                                '#47b784',
                            ],
                            borderWidth: 3,
                        }
                    ]
                },
                options: {
                    elements: { 
                        point: { 
                            radius: 0 
                        } 
                    },
                    legend: {
                        display: true
                    },
                    responsive: true,
                    title:{
                        display:false,
                        text:'Inschrijvingen in verloop van tijd'
                    },
                    lineTension: 1,
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                    },
                    scales: {
                        xAxes: [{
                            type: 'time',
                            time: {
                                parser: 'YYYY-MM-DD HH:mm:ss',
                                unit: 'day',
                                displayFormats: {
                                    'day': 'DD - MM - YY'
                                }
                            },
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Datums',
                                fontSize: 20,
                            },
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: 'Inschrijvingen',
                                fontSize: 20,
                            },
                            ticks: {
                                beginAtZero: true,
                                padding: 25,
                            }
                        }]
                    }
                }
            })
        },
    },
}
