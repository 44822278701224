
import AlertModal from '@/components/AlertModal.vue'
import { mapState } from 'vuex'
import { SUBSCRIBE_REQUEST } from '@/store/actions/subscription.ts'

export default{
    name: 'AllSubscriptions',
    components: {
        AlertModal
    },
    props: {
        scope: {type: String},
    },
    data() {
        return {
            mounted: false,
            filter_done: false,
            student_list: [{ value: null, text: 'Loading...', disabled: true }],
            student_list_not_done: [{ value: null, text: 'Loading...', disabled: true }],
            excursion_list: [{ value: null, text: 'Loading...', disabled: true }],

            subscription: {},
            warning: null,

            success: '',
            errors: [],
        }
    },
    methods:{
        onSubmit() {
            this.subscribe()
        },
        loadData() {
            this.student_list = [{ value: null, text: '-- Selecteer student --', disabled: true }]
            this.student_list_not_done = [{ value: null, text: '-- Selecteer student --', disabled: true }]
            this.excursion_list = [{
                value: null,
                text: (this.scope === 'keuzedeel') ? '-- Selecteer keuzedeel --' : '-- Selecteer activiteit/reis --',
                disabled: true
            }]

            let scoped_subscriptions = this.subscriptions.filter(sub => (sub.scope === this.scope))
            // Fill students lists
            if (this.scope === 'x-week') {
                this.profiles.filter(prof => prof.hours > 0).map(prof => {
                    try {
                        let hours_subscribed = 0
                        let subscriptions = scoped_subscriptions.filter(sub => (sub.student_id === prof.id)
                        )
                        for (let i=0; i<subscriptions.length; i++)  {
                            let excursion_hours = this.excursions_object[subscriptions[i].excursion_id].hours
                            hours_subscribed += excursion_hours
                        }

                        let option = null
                        if (hours_subscribed >= prof.hours) {
                            option = { value: prof.id, text: prof.number }
                        } else {
                            option = { value: prof.id, text: prof.number + ' (' + (prof.hours - hours_subscribed) + ')' }
                            this.student_list_not_done.push(option)
                        }
                        this.student_list.push(option)
                    } catch (err) {
                        this.errors.push('Niet alle studenten zijn ingeladen door de error:\n' + err)
                    }
                })
            } else {
                this.profiles.filter(prof => prof.keuzedelen).map(prof => {
                    try {
                        let option = { value: prof.id, text: prof.number }
                        let first_subscriptions = scoped_subscriptions
                            .filter(sub => (sub.student_id === prof.id && sub.preference == 1))
                        if (!(first_subscriptions.length == 1)) {
                            this.student_list_not_done.push(option)
                        }
                        this.student_list.push(option)
                    } catch (err) {
                        this.errors.push('Niet alle studenten zijn ingeladen door de error:\n' + err)
                    }
                })
            }

            // Fill excursion list
            var filtered_excursions = null
            if (this.scope === 'x-week')
                filtered_excursions = this.excursions.filter(ex => (ex.scope != 'keuzedeel' && ex.active))
            else
                filtered_excursions = this.excursions.filter(ex => (ex.scope === 'keuzedeel' && ex.active))

            filtered_excursions.map(ex => {
                try {
                    /*
                    if (ex.max_subscribers > ex.subscriptions.length)
                        this.excursion_list.push({
                            value: ex._id, text: ex.name + (this.scope === 'x-week' ? ' (' + ex.hours + ')' : '')
                        })
                    */
                    this.excursion_list.push({
                        value: ex._id, text: ex.name + (this.scope === 'x-week' ? ' (' + ex.hours + ')' : '')
                    })
                } catch (err) {
                    this.errors.push('Niet alle excursies zijn geladen door de error:\n' + err)
                }
            })
        },
        subscribe() {
            this.$store.dispatch(SUBSCRIBE_REQUEST, this.subscription).then(() => {
                this.success = 'Inschrijving is gelukt!'
                this.subscription = {}
            })
        }
    },
    mounted() {
        this.mounted = true
        this.loadData()
    },
    computed: {
        ...mapState({
            profiles: (state: any) => state.user.profiles,
            subscriptions: (state: any) => state.subscription.subscriptions,
            excursions: (state: any) => state.excursion.excursions,
            excursions_object: (state: any) => state.excursion.excursions_object,
        }),
        valid() {
            if (!this.mounted) return false
            return (Object.keys(this.subscription).length === 2 && this.warning === null)
        }
    },
    watch: {
        subscription: {
            async handler(newValue) {
                this.warning = null
                if (Object.keys(newValue).length === 2) {
                    var _known = await this.subscriptions.filter(ex =>
                        ex.student_id === newValue.student_id && ex.excursion_id === newValue.excursion_id
                    )
                    if (Object.keys(_known).length > 0)
                        this.warning = 'Student staat al ingeschreven voor deze activiteit/reis'
                }
            },
            deep: true
        },
        subscriptions: function(newValue, oldValue) {
            if (!this.mounted) return
            if (newValue != oldValue)
                this.loadData()
        },
        $route (to, from) {
            this.loadData()
        }
    }
}
