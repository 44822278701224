
import { mapState } from 'vuex'
import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable.vue'
import ProgressCircle from '@/components/ProgressCircle.vue'

export default{
    name: 'AllSubscriptions',
    components: {
        SubscriptionsTable,
        ProgressCircle
    },
    data() {
        return {
            mounted: false,
            subscriptions_data: [],
            students_done: 0,
            students_not_done: 0,

            filter_done: false,
            active_items: null,
            email_addresses: null,

            table_fields: [
                {
                    key: 'number',
                    label: 'Student nummer',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Voornaam',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Achternaam',
                    sortable: true
                },
                {
                    key: 'done',
                    label: 'Heeft een (eerste) keuze',
                    sortable: true
                },
            ],
        }
    },
    methods:{
        loadSubscriptions() {
            this.students_done = 0
            this.students_not_done = 0

            let scoped_subscriptions = this.subscriptions.filter(sub => sub.scope === 'keuzedeel')
            this.subscriptions_data = this.profiles
                .filter(prof => prof.keuzedelen)
                .map(prof => {
                    try {
                        let first_subscriptions = scoped_subscriptions
                            .filter(sub => (sub.student_id === prof.id && sub.preference == 1))
                        if (first_subscriptions.length == 1) {
                            this.students_done++
                            prof.done = true
                        } else {
                            this.students_not_done++
                            prof.done = false
                        }
                        return prof
                    } catch (err) {
                        return {'first_name': 'error', 'last_name_prefix': err}
                    }
                })
        },
        setActiveItems(items) {
            this.active_items = items
        },
        setEmailAddresses() {
            this.email_addresses = this.active_items
                .map(data => { return data.email })
                .join('; ')
        }
    },
    mounted() {
        this.mounted = true
        this.loadSubscriptions()
    },
    computed: {
        ...mapState({
            profiles: (state: any) => state.user.profiles,
            subscriptions: (state: any) => state.subscription.subscriptions,
            excursions_object: (state: any) => state.excursion.excursions_object
        }),
        getSubscriptions() {
            if (!(this.mounted)) return
            if (!this.filter_done) return this.subscriptions_data
            return this.subscriptions_data.filter(sub => !(sub.done))
        },
        getStudentsDone() {
            if (!(this.mounted)) return
            return this.students_done
        },
        getStudentsNotDone() {
            if (!(this.mounted)) return
            return this.students_not_done
        },
    },
    watch: {
        active_items: function() {
            if (this.email_addresses === null) return
            this.setEmailAddresses()
        },
        subscriptions: function(newValue, oldValue) {
            if (!this.mounted) return
            if (newValue != oldValue)
                this.loadSubscriptions()
        }
    }
}
