
import { mapState } from 'vuex'

import LineChart from '@/components/LineChart.vue'
import { Parser as Json2CsvParser } from 'json2csv'
import { export_csv_fields } from '@/utils/meta.ts'
import { parseDate } from '@/utils/index.ts'

import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable.vue'

export default{
    name: 'AllSubscriptions',
    components: {
        SubscriptionsTable,
        LineChart,
    },
    props: {
        scope: {type: String},
    },
    data() {
        return {
            mounted: false,

            subscriptions_data: [],

            plot_data: {
                xdata: [],
                ydata: [],
            },
            table_fields: [
                {
                    key: 'index',
                    label: '#',
                    sortable: true,
                },
                {
                    key: 'excursion_name',
                    label: 'Activiteit/Reis',
                    sortable: true,
                    tdClass: 'excursion_column'
                },
                {
                    key: 'excursion_name',
                    label: 'Keuzedeel',
                    sortable: true,
                    tdClass: 'excursion_column'
                },
                {
                    key: 'number',
                    label: 'Student nummer',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Voornaam',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Achternaam',
                    sortable: true
                },
                {
                    key: 'preference',
                    label: 'Keuze',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: true
                },
            ],
            detail_fields: [
                {
                    key: 'grade',
                    label: 'Klas',
                },
                {
                    key: 'phone',
                    label: 'Telefoonnummer'
                },
                {
                    key: 'email',
                    label: 'e-mail'
                },
                {
                    key: 'timestamp',
                    label: 'Inschrijf datum',
                    //formatter: value => this.parseDate(value)
                },
            ],
        }
    },
    methods: {
        loadSubscriptions() {
            let subscriptions_counter = 0
            this.plot_data.xdata = []
            this.plot_data.ydata = []

            this.subscriptions_data = this.subscriptions.filter(sub => sub.scope === this.scope)
                .map(sub => {
                    subscriptions_counter++
                    this.plot_data.xdata.push(sub.timestamp)
                    this.plot_data.ydata.push(subscriptions_counter)

                    try {
                        let excursion =
                            (({name, type, costs_students}) =>
                                ({name, type, costs_students}))(this.excursions_object[sub.excursion_id])
                        excursion['excursion_name'] = excursion.name

                        let subscription_profile = {
                            'index': subscriptions_counter,
                            ...this.profiles_object[sub.student_id],
                            ...excursion,
                            ...sub
                        }

                        subscription_profile.document_issue_date_parsed =
                            parseDate(subscription_profile.document_issue_date)
                        subscription_profile.document_validity_date_parsed =
                            parseDate(subscription_profile.document_validity_date)
                        subscription_profile.bday_parsed =
                            parseDate(subscription_profile.bday)

                        // Just to make sure, the _id of the object must be the subscription _id!
                        subscription_profile._id = sub._id
                        return subscription_profile
                    } catch (err) {
                        return {'first_name': 'error', 'last_name_prefix': err}
                    }
                })
                .reverse()
        },
        exportCSV() {
            const parser = new Json2CsvParser({ fields: export_csv_fields })
            const csv = parser.parse(this.subscriptions_data)
 
            let export_data = 'text/csv;charset=utf-8,' + encodeURI(csv)

            var a = document.createElement('a')
            a.href = 'data:' + export_data
            a.download = 'inschrijvingen.csv'
            document.body.appendChild(a)
            a.click()
            a.remove()
        }
    },
    mounted() {
        this.mounted = true
        this.loadSubscriptions()
        this.$refs['line-chard'].createChart(this.plot_data)
    },
    computed: {
        ...mapState({
            profiles_object: (state: any) => state.user.profiles_object,
            subscriptions: (state: any) => state.subscription.subscriptions,
            excursions_object: (state: any) => state.excursion.excursions_object
        }),
        getSubscriptions() {
            if (!(this.mounted)) return
            return this.subscriptions_data
        },
        tableFields() {
            if (this.scope === 'keuzedeel') {
                return this.table_fields.filter(it => !(it.label === 'Activiteit/Reis'))
            } else {
                return this.table_fields.filter(it => !(it.key === 'preference' || it.label === 'Keuzedeel'))
            }
        }
    },
    watch: {
        subscriptions: function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.loadSubscriptions()
                this.$refs['line-chard'].createChart(this.plot_data)
            }
        },
        $route (to, from) {
            this.loadSubscriptions()
        }
    }
}
