
import { mapState } from 'vuex'
import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable.vue'
import ProgressCircle from '@/components/ProgressCircle.vue'

export default{
    name: 'AllSubscriptions',
    components: {
        SubscriptionsTable,
        ProgressCircle
    },
    data() {
        return {
            mounted: false,
            hours_data: [],
            mandatory_hours: 0,
            subscribed_hours: 0,
            students_done: 0,
            students_not_done: 0,

            filter_done: false,
            active_items: null,
            email_addresses: null,

            table_fields: [
                {
                    key: 'number',
                    label: 'Student nummer',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Voornaam',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Achternaam',
                    sortable: true
                },
                {
                    key: 'hours',
                    label: 'Verplichte punten',
                    sortable: true
                },
                {
                    key: 'hours_subscribed',
                    label: 'Ingeschreven punten',
                    sortable: true
                },
            ],
        }
    },
    methods:{
        loadHours() {
            this.mandatory_hours = 0
            this.subscribed_hours = 0
            this.students_done = 0
            this.students_not_done = 0

            let scoped_subscriptions = this.subscriptions.filter(sub => (sub.scope === 'x-week'))
            this.hours_data = this.profiles
                .filter(prof => prof.hours > 0)
                .map(prof => {
                    try {
                        this.mandatory_hours += prof.hours
                        let hours_subscribed = 0
                        let subscriptions = scoped_subscriptions.filter(sub => (sub.student_id === prof.id))
                        for (let i=0; i<subscriptions.length; i++)  {
                            let excursion_hours = this.excursions_object[subscriptions[i].excursion_id].hours
                            hours_subscribed += excursion_hours
                        }
                        prof.hours_subscribed = hours_subscribed
                        this.subscribed_hours += hours_subscribed
                        if (hours_subscribed >= prof.hours) {
                            this.students_done++
                        } else {
                            this.students_not_done++
                        }
                        return prof
                    } catch (err) {
                        return {'first_name': 'error', 'last_name_prefix': err}
                    }
                })
        },
        setActiveItems(items) {
            this.active_items = items
        },
        setEmailAddresses() {
            this.email_addresses = this.active_items
                .map(data => { return data.email })
                .join('; ')
        }
    },
    mounted() {
        this.mounted = true
        this.loadHours()
    },
    computed: {
        ...mapState({
            profiles: (state: any) => state.user.profiles,
            subscriptions: (state: any) => state.subscription.subscriptions,
            excursions_object: (state: any) => state.excursion.excursions_object
        }),
        getHours() {
            if (!(this.mounted)) return
            if (!this.filter_done) return this.hours_data
            return this.hours_data.filter(data => data.hours_subscribed < data.hours)
        },
        getMandatoryHours() {
            if (!(this.mounted)) return
            return this.mandatory_hours
        },
        getSubscribedHours() {
            if (!(this.mounted)) return
            return this.subscribed_hours
        },
        getStudentsDone() {
            if (!(this.mounted)) return
            return this.students_done
        },
        getStudentsNotDone() {
            if (!(this.mounted)) return
            return this.students_not_done
        },
    },
    watch: {
        active_items: function() {
            if (this.email_addresses === null) return
            this.setEmailAddresses()
        },
        subscriptions: function(newValue, oldValue) {
            if (!this.mounted) return
            if (newValue != oldValue)
                this.loadHours()
        }
    }
}
